import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/codebuild/output/src3106761984/src/simplified-imaluum/context/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src3106761984/src/simplified-imaluum/context/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src3106761984/src/simplified-imaluum/node_modules/react-hot-toast/dist/index.mjs");
